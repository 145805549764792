/*
Esquema de colores:
Rojo: 		#e41819
Amarillo:	#efd919

*/

body {
    background: #e41819;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: auto;
}
header {} a:focus {
    color: #fff;
    text-decoration: none
}
.negrita{font-weight: bold;}
.separacion-top{margin-top: 15px}
.btnenlace, .btnenlace:link, .btnenlace:visited, .btnenlace:focus {
    display: block;
    background-color: rgba(0, 0, 0, 0);
    line-height: 1em;
    color: #fff;
    padding: 5px 0px;
    -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out;
    /* Chrome y Safari */
    
    -o-transition: background-color 0.3s ease-out, color 0.3s ease-out;
    /* Opera */
    
    -moz-transition: background-color 0.3s ease-out, color 0.3s ease-out;
    /* Mozilla Firefox */
    
    -ms-transition: background-color 0.3s ease-out, color 0.3s ease-out;
    /* Internet Explorer */
    
    transition: background-color 0.3s ease-out, color 0.3s ease-out;
    /* W3C */
}
.btnenlace:hover {
    background-color: #efd919;
    color: #e41819;
    text-decoration: none;
}
.ventana-modal {
    position: relative;
    background: none repeat scroll 0 0 #fff;
    padding: 20px 30px;
    max-width: 770px;
    width: auto;
    margin: 20px auto;
    color: #000;
    font-weight: lighter;
    box-shadow: -6px 6px 11px rgba(0, 0, 0, 0.4);
}
.ventana-modal h1{
	color: #e41819;
    margin-bottom: 0; margin-top: 0;
}
.ventana-modal h2{font-size: 1.5em;color: #e41819; margin-top: 30px;}
.ventana-modal h2:first-child{margin-top: 25px;}
.ventana-modal h3{font-size: 1.3em;}
.ventana-modal h5{font-size: 1.2em}
.ventana-modal p{}
.ventana-modal img{
    margin-bottom: 15px;
}
.ventana-modal .contenedor-mapa{margin-bottom: 20px;}
.mfp-bg {
    background: none repeat scroll 0 0 #e41819;
}
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
    opacity: 0.65;
}
.ventana-modal img {
    max-width: 100%;
}
.container {}
/*Cabecera*/

#cabecera {
    margin-top: 40px;
    z-index: 10;
    position: relative;
    left: 0;
    right: 0;
}
#cabecera-noticias {
    margin-top: 40px;
    z-index: 0;
    position: relative;
    left: 0;
    right: 0;
}
@media(max-width: 600px){
    #cabecera{margin-top: 6px}
    #cabecera-noticias {margin-top: 6px;}
}

#logo {
    display: block;
    margin-top: 4px;
}
#logo img {
    width: 104%;
    max-width: 280px;
}
@media(max-width: 991px) {
    #logo {
        text-align: center;
    }
    #logo img {
        max-width: 250px;
    }
}
@media(max-width: 550px){

    #logo img{max-width: 90px}
}
.social {
    margin-top: 62px;
}
.social ul {
    padding: 0;
}
.social ul li {
    display: inline-block;
    padding-left: 5px;
    line-height: 29px;
    vertical-align: bottom;
}
.social ul li:first-child {
    padding-left: 0;
}
.social ul li a {
    padding: 3px 6px;
    background-color: #fff;
    color: #e41819;
    -webkit-transition: background-color 0.3s ease-out;
    /* Chrome y Safari */
    
    -o-transition: background-color 0.3s ease-out;
    /* Opera */
    
    -moz-transition: background-color 0.3s ease-out;
    /* Mozilla Firefox */
    
    -ms-transition: background-color 0.3s ease-out;
    /* Internet Explorer */
    
    transition: background-color 0.3s ease-out;
    /* W3C */
}
.social ul li a:hover {
    background-color: #efd919;
}
.social ul li a i {} .social ul li a.sobre {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 29px;
    font-size: 2.178em;
    background: #e41819;
    color: #fff;
    -webkit-transition: color 0.3s ease-out;
    /* Chrome y Safari */
    
    -o-transition: color 0.3s ease-out;
    /* Opera */
    
    -moz-transition: color 0.3s ease-out;
    /* Mozilla Firefox */
    
    -ms-transition: color 0.3s ease-out;
    /* Internet Explorer */
    
    transition: color 0.3s ease-out;
    /* W3C */
}
.social ul li a.sobre:hover {
    color: #efd919;
}
.social ul li a.sobre i {}
/*Flechas*/

.fp-controlArrow.fp-prev, .fp-controlArrow.fp-next {
    width: 57px;
    height: 100px;
    border: none;
    color: #ad0f10;
    background-position: 0 0;
    -webkit-transition: color 0.3s ease-out, color 0.3s ease-out;
    /* Chrome y Safari */
    
    -o-transition: color 0.3s ease-out, color 0.3s ease-out;
    /* Opera */
    
    -moz-transition: color 0.3s ease-out, color 0.3s ease-out;
    /* Mozilla Firefox */
    
    -ms-transition: color 0.3s ease-out, color 0.3s ease-out;
    /* Internet Explorer */
    
    transition: color 0.3s ease-out, color 0.3s ease-out;
    /* W3C */
}
.fp-controlArrow.fp-prev:hover, .fp-controlArrow.fp-next:hover {
    background-position: 0 -100px;
    color: #efd919;
}
.fp-controlArrow.fp-prev {
    background: url('../img/izquierda-negro.png') no-repeat;
    margin-left: 10px;
}
.fp-controlArrow.fp-next {
    background: url('../img/derecha-negro.png') no-repeat;
    margin-right: 10px;
}
.flechas-slide {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1120px;
    position: absolute;
    top: 48%;
}
.fp-controlArrow.fp-prev span {
    left: 41px;
}
.fp-controlArrow.fp-next span {
    right: 41px;
}
.fp-controlArrow span {
    position: absolute;
    top: 40%;
}
@media(max-width: 700px){
    .flechas-slide{display: none;}
}
/*Menu productos*/

.menu-productos {
    top: 77%;
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    text-align: center;
}
.menu-productos ul {
    padding: 0 0 0 25px;
}
.menu-productos ul li {
    display: inline-block;
    vertical-align: top;
    margin-left: -15px;
}
.menu-productos ul li a {
    height: 100px;
    display: block;
    vertical-align: top;
}
.menu-productos ul li a img {
    vertical-align: top;
    width: 90px;
    -webkit-transition: width 0.1s ease-out;
    -o-transition: width 0.1s ease-out;
    -moz-transition: width 0.1s ease-out;
    -ms-transition: width 0.1s ease-out;
    transition: width 0.1s ease-out;
}
.menu-productos ul li a:hover img, .menu-productos ul li a.active img {
    width: 120px;
}
@media (max-height: 840px) {
    .menu-productos {
        top: 80%;
    }
}
@media (max-height: 780px) {
    .menu-productos {
        top: 77%;
    }
}
@media (max-height: 500px) {
    .menu-productos {
        display: none;
        bottom: 10px;
    }
    .menu-productos ul li a {
        height: auto;
    }
    .menu-productos ul li a img {
        width: 50px;
        height: auto;
        -webkit-transition: width 0.1s ease-out;
        -o-transition: width 0.1s ease-out;
        -moz-transition: width 0.1s ease-out;
        -ms-transition: width 0.1s ease-out;
        transition: width 0.1s ease-out;
    }
    .menu-productos ul li a:hover img, .menu-productos ul li a.active img {
        width: 60px;
    }
}
@media (max-width: 580px) {
    .menu-productos{display: none}
    .menu-productos ul li a img {
        width: 50px;
        -webkit-transition: width 0.1s ease-out;
        -o-transition: width 0.1s ease-out;
        -moz-transition: width 0.1s ease-out;
        -ms-transition: width 0.1s ease-out;
        transition: width 0.1s ease-out;
    }
    .menu-productos ul li a:hover img, .menu-productos ul li a.active img {
        width: 60px;
    }
}
#menuwrapper ul {
    margin-left: 2px;
}
#menuwrapper ul, #menuwrapper ul li {
    margin: 0;
    padding: 0;
    list-style: none;
}
/* We apply background color and border bottom white and width to 150px */

#menuwrapper ul li {
    background-color: none;
    position: relative;
    width: 150px;
    cursor: pointer;
    word-wrap: normal;
    letter-spacing: 0.02em;
}
/* We apply the background hover color when user hover the mouse over of the li component */

#menuwrapper ul li:hover {
    background-color: none;
    position: relative;
}
#menuwrapper ul li a:hover {
    color: #efd914;
}
/* We apply the link style */

#menuwrapper ul li a {
    padding: 0 5px 1px 5px;
    color: #ffffff;
    display: inline-block;
    text-decoration: none;
    font-size: 0.9em;
    -webkit-transition: color 0.3s ease-out;
    /* Chrome y Safari */
    
    -o-transition: color 0.3s ease-out;
    /* Opera */
    
    -moz-transition: color 0.3s ease-out;
    /* Mozilla Firefox */
    
    -ms-transition: color 0.3s ease-out;
    /* Internet Explorer */
    
    transition: color 0.3s ease-out;
    /* W3C */
}
/**** SECOND LEVEL MENU ****/
/* We make the position to absolute for flyout menu and hidden the ul until the user hover the parent li item */

#menuwrapper ul li ul {
    position: absolute;
    z-index: 9995;
    top: 0;
    left: 150px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}
/* When user has hovered the li item, we show the ul list by applying display:block, note: 150px is the individual menu width.  */

#menuwrapper ul li:hover ul {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
}
/* we apply different background color to 2nd level menu items*/

#menuwrapper ul li ul li {
    width: 140px;
}
/* We change the background color for the level 2 submenu when hovering the menu */

#menuwrapper ul li:hover ul li:hover {}
/* We style the color of level 2 links */

#menuwrapper ul li ul li a {
    display: inline-block;
    width: 100%;
}
/**** THIRD LEVEL MENU ****/
/* We need to hide the 3rd menu, when hovering the first level menu */

#menuwrapper ul li:hover ul li ul {
    top: 0;
    position: absolute;
    z-index: 9997;
    left: 140px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}
#menuwrapper ul li ul li ul {
    top: 0;
    position: absolute;
    z-index: 9997;
    left: 110px;
}
/* We show the third level menu only when they hover the second level menu parent */

#menuwrapper ul li:hover ul li:hover ul {
    visibility: visible;
    opacity: 1;
    display: block;
    transition-delay: 0s;
}
/* We change the background color for the level 3 submenu*/

#menuwrapper ul li:hover ul li:hover ul li {}
/* We change the background color for the level 3 submenu when hovering the menu */

// #menuwrapper ul li:hover ul li:hover ul li:hover {} 
// #menuwrapper ul li:hover ul li:hover ul li a {} 
#menuwrapper ul li ul li ul li a {
    display: inline-block;
    width: 100%;
}


/* Clear float */

.clear {
    clear: both;
}
#menuwrapper .menu-principal li.active > a {
    color: #efd914;
    font-weight: bold;
}
#menu-oculto-container {} 
#menu-oculto {
    display: none;
}
#menu-oculto-container .slicknav_menu {
    background: none;
}
#menu-oculto-container .slicknav_menu .slicknav_btn {
    background: #fff;
}
#menu-oculto-container .slicknav_menu .slicknav_btn span.slicknav_icon-bar {
    color: #e41819;
    border: none;
    box-shadow: none;
    border-radius: none;
    background-color: #e41819;
}
#menu-oculto-container .slicknav_menu ul.slicknav_nav {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
}
#menu-oculto-container .slicknav_menu ul.slicknav_nav li a:hover {
    background: #e41819;
    color: #fff;
}
#menu-oculto li {} .idiomas {
    padding: 0;
    margin-left: -4px;
}
.idiomas li {
    display: inline-block;
    color: #fff;
}
.idiomas li a {
    text-decoration: none;
    font-weight: lighter;
    display: block;
    width: 19px;
    text-align: center;
    color: #fff;
    -webkit-transition: color 0.3s ease-out;
    /* Chrome y Safari */
    
    -o-transition: color 0.3s ease-out;
    /* Opera */
    
    -moz-transition: color 0.3s ease-out;
    /* Mozilla Firefox */
    
    -ms-transition: color 0.3s ease-out;
    /* Internet Explorer */
    
    transition: color 0.3s ease-out;
    /* W3C */
}
.idiomas li a:hover, .idiomas li a.active {
    color: #efd919;
    font-weight: normal;
}
.redes-oculto {
    position: fixed;
    top: 0;
}
.redes-oculto ul {} .redes-oculto ul li {
    display: inline-block;
    padding-left: 5px;
    line-height: 29px;
    vertical-align: bottom;
}
.redes-oculto ul li a {
    padding: 3px 6px;
    background-color: #fff;
    color: #e41819;
    -webkit-transition: background-color 0.3s ease-out;
    /* Chrome y Safari */
    
    -o-transition: background-color 0.3s ease-out;
    /* Opera */
    
    -moz-transition: background-color 0.3s ease-out;
    /* Mozilla Firefox */
    
    -ms-transition: background-color 0.3s ease-out;
    /* Internet Explorer */
    
    transition: background-color 0.3s ease-out;
    /* W3C */
}
.redes-oculto ul li a:hover {
    background-color: #efd919;
}
.redes-oculto ul li a.sobre {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 29px;
    font-size: 2.178em;
    background: #e41819;
    color: #fff;
    -webkit-transition: color 0.3s ease-out;
    /* Chrome y Safari */
    
    -o-transition: color 0.3s ease-out;
    /* Opera */
    
    -moz-transition: color 0.3s ease-out;
    /* Mozilla Firefox */
    
    -ms-transition: color 0.3s ease-out;
    /* Internet Explorer */
    
    transition: color 0.3s ease-out;
    /* W3C */
}
.redes-oculto ul li a.sobre:hover {
    color: #efd919;
}
/*Fin menu*/

#cuerpo {} #cuerpo .imagen {
    text-align: center;
}
#cuerpo .imagen img {
    max-width: 580px;
    width: 100%;
}
@media (max-height: 780px) {
    #cuerpo .contenido{
        margin-top: 10%
    }
    #cuerpo .imagen img {
        /*max-width: 400px;*/
        max-width: 70%;
    }
}
@media (max-height: 650px) {
    #cuerpo .imagen img {
        /*max-width: 350px;*/
        max-width: 60%
    }
}
#cuerpo .contenido {
    margin-top: -15%;
    color: #fff;
}
#cuerpo #sec-productos .contenido {
    margin-top: -18%;
    color: #fff;
}
#cuerpo #sec-agricultores .contenido {
    margin-top: -14%;
    color: #fff;
}
@media (max-width: 991px) {
    #cuerpo .contenido {
        margin-top: -10%;
    }
}
@media (max-height: 780px) {
    #cuerpo .contenido{
        margin-top: -10%
    }
}
#cuerpo .contenido p {
    font-size: 0.94em;
    letter-spacing: 0.02em;
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
}
#cuerpo .contenido h2.dancing {
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-size: 2.5em;
    margin-top: 0px;
}
#cuerpo .enlaces {
    text-align: center;
}
#cuerpo .enlaces a {
    border: 1px solid;
    font-size: 0.94em;
    width: 111px;
    margin-bottom: 5px;
}

@media(max-width: 600px){
    #cuerpo .contenido h2.dancing{font-size: 1.2em}
    #cuerpo .contenido p{font-size: 0.9em}
}
/**
* Fade-move animation for second dialog
*/
/* at start */

.my-mfp-slide-bottom .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
    -moz-transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
    -ms-transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
    -o-transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
    transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
}
/* animate in */

.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: translateY(0) perspective( 600px) rotateX( 0);
    -moz-transform: translateY(0) perspective( 600px) rotateX( 0);
    -ms-transform: translateY(0) perspective( 600px) rotateX( 0);
    -o-transform: translateY(0) perspective( 600px) rotateX( 0);
    transform: translateY(0) perspective( 600px) rotateX( 0);
}
/* animate out */

.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
    opacity: 0;
    -webkit-transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
    -moz-transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
    -ms-transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
    -o-transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
    transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
}
/* Dark overlay, start state */

.my-mfp-slide-bottom.mfp-bg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}
/* animate in */

.my-mfp-slide-bottom.mfp-ready.mfp-bg {
    opacity: 0.8;
}
/* animate out */

.my-mfp-slide-bottom.mfp-removing.mfp-bg {
    opacity: 0;
}



/*Mapa contacto*/
.separacion-contacto{margin-left:8px; }
.contenedor-mapa {
    width: 100%;
    height: 300px;
    position: relative;
    display: block;
    margin-top: 20px;
}
#mapa-contacto {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999999999;
}
#mapa-contacto div img {
    max-width: none;
}
#mapa-centros {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999999999;
}
#mapa-centros div img {
    max-width: none;
}

/*Modal productos*/
#modal-productos .mfp-close{/*display: none*/}
#modal-productos .pr{margin-bottom: 20px;}
#modal-productos .obj{border: 1px solid #f00;  margin-bottom: 19px;}
#modal-productos .obj:last-child{border-bottom:0px solid #000;}
#modal-productos h1{ margin-bottom: 7px;margin-top: 14px;}
#modal-productos h2{}
#modal-productos h3{}
#modal-productos h4{ text-align: center; margin-top: -17px;}
#modal-productos .prod-pdf{margin-bottom: 0; background: #e41819; text-align: right;}
#modal-productos .prod-pdf a{
    background: url("../img/pdf2.png") no-repeat scroll 0 0 / 100% auto rgba(0, 0, 0, 0);
    display: block;
    height: 51px;
    margin-top: 5px;
    overflow: hidden;
    width: 43px;
}
#modal-productos .prod-pdf a:hover{
    background-position: 0% 100%;
}
#modal-productos .prod-pdf img{text-align: right; max-width: 100%}
#modal-productos .prod-pdf p{
    text-align: left;
    color: #fff;
    font-size: 1.15em;
    font-weight: normal;
    margin-bottom: 0;
    padding: 8px 0;
}
#modal-productos .imagen{text-align: center;}
#modal-productos .imagen img{max-width: 100%; margin-bottom: 0}
#modal-productos .quitar-padding{padding-left: 0;}

.ventana-modal .cuadro-rojo{background: #fff; border: 2px solid #e41819; }
.ventana-modal .cuadro-rojo ul{margin:20px 0; padding: 0}
.ventana-modal .cuadro-rojo ul li{color: #e41819; font-weight: normal; font-size: 1.1em}
@media(max-width: 767px) {
    .ventana-modal .cuadro-rojo ul{padding-left: 40px; margin: 0}
    .ventana-modal .cuadro-rojo{padding-top:20px; padding-bottom: 20px;}
    #modal-productos .prod-pdf a{
        height: 31px;
        width: 26px;
    }
    #modal-productos .nombre{margin-top: 16px}
}
@media(max-width: 500px){
    #sec-productos .btnenlace{
        margin-top: 20px;
    }
}
.ventana-modal .table tr th, .ventana-modal .table tr td{border-bottom: 1px solid #ddd;border-top: 1px solid #ddd}

.mnu-noticias{
        
}
.mnu-noticias #menuwrapper ul{background: #e41819; z-index: 9999}
.mnu-noticias #menuwrapper ul li{background: #e41819; z-index: 9999}

#noticias{margin: 40px 47px; background: #fff;}
#noticias .noticia{ padding: 20px;}
#noticias .noticia h2{ margin: 0; font-size: 2em;}
#noticias .noticia h2 a{color: #000;}
#noticias .noticia img{max-width: 100%;}
#noticias .noticia .content{padding-right: 30px}
#noticias{}
#noticias{}

@media(max-width: 1200px){
    #noticias .noticia h2{
        font-size: 1.5em;
    }
}
video{
    max-width: 100%
}
footer#footer{
    position: fixed;
    bottom: 0;
    right: 0;
}
footer#footer p{color: #fff; font-size: 0.75em; margin:7px 25px}
footer#footer p a{color: #fff; font-weight: bold;}

#politica_desplegable:focus{
    color:#337ab7;
}



.admin{
    background: #ffffff;
    min-height: 400px;
    margin-bottom: 60px;
}
.admin h1{
    font-size: 1.5em;
    text-decoration: underline;
}
.admin h2{
    font-size: 1.5em;
    margin: 10px 0 0 0;
}
.admin .dataTables_wrapper{
    margin-top: 30px;
}
.admin .dataTables_wrapper form{
    display: inline-block;
}
.enlacersc, .enlacersc:link, .enlacersc:visited, .enlacersc:focus {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    display: block;
    line-height: 1em;
    padding: 5px 0;
    transition: background-color 0.3s ease-out 0s, color 0.3s ease-out 0s;

    border: 1px solid;
    font-size: 0.94em;
    margin-bottom: 5px;
    width: 111px;
    text-align: center;
    font-weight:normal;
}
.enlacersc:hover{
    background-color: #efd919;
    color: #e41819;
    text-decoration: none;
}

//La union juniors
.launionjuniors{

}
.launionjuniors a{
    position: fixed;
    right: -70px;
    top: 45%;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.launionjuniors a:hover{
    right: 0px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.launionjuniors a img{
    width: 150px;
}

//Formularios

.formulario-contacto {
    margin-top: 20px;
}
.formulario-contacto p {
    padding: 0
}
.formulario-contacto .campo {
    border: 1px solid #e41819;
    color: #000;
    margin-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 0.8em;
    padding: 5px;
}
.formulario-contacto .check {} 
.formulario-contacto form input {} 
.formulario-contacto form textarea {} 
.formulario-contacto {} 
.datos-contacto {
    margin-top: 50px;
    margin-left: -16px;
} 
.datos-contacto p{
    margin:0;
} 
.datos-contacto p a{
    text-decoration: none;
    color: #000;
}
.checkbox {
    display: inline-block;
    cursor: pointer;
    font-size: 13px;
    margin-right: 10px;
    line-height: 18px;
}
.formulario-contacto label{font-weight: lighter;}
.formulario-contacto .btn-enviar{
    background-color: #f00;
    border: 1px solid #fff;
    color: #fff;
    font-weight: bold;
    padding: 1px 10px;
    font-size: 1.3em;
    display: block;
}
// input#Option[type=checkbox] {
//     display: none;
// }
// .checkbox:before {
//     content: "";
//     display: inline-block;
//     width: 18px;
//     height: 18px;
//     vertical-align: middle;
//     background-color: #e41819;
//     color: #efd919;
//     text-align: center;
//     box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(255, 255, 255, .8);
//     border-radius: 3px;
// }
// input[type=checkbox]:checked + .checkbox:before {
//     content: "\2713";
//     text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
//     font-size: 15px;
// }


.formulario-trabaja {
    margin-top: 20px;
    .seccion{
        margin-top: 22px;
        .row{
            margin-bottom: 15px;
        }
    }
    p {
        padding: 0
    }
    .has-error .campo, .campo {
        border: 1px solid #e41819;
        color: #000;
        margin-bottom: 0px;
        width: 100%;
        font-weight: bold;
        font-size: 0.8em;
        padding: 5px;
    }
    .has-success{
        .campo{
            border: 1px solid #307f30;
        }
    }
    h3{
        margin: 1px 0 10px;
    }
    a:focus{
        color: #000;
    }
    .checkbox{
        margin-right: 0
    }
    label{
        font-weight: lighter;
    }
    .btn-enviar{
        background-color: #f00;
        border: 1px solid #fff;
        color: #fff;
        font-weight: bold;
        padding: 1px 10px;
        font-size: 1.3em;
        display: block;
    }
    h1{
        margin: 10px 0 20px 0;
        text-align: center;
    }
    #oferta{
        height: 45px;
        color: #6f6f6f;
        font-size: 17px;
    }
    .separado{
        border-bottom: 3px solid #c41010;
        padding: 0 15px 30px;
    }
    #titulo1{
        margin-top: 0px;
        margin-bottom: 23px;
    }
    #titulo2{
        margin-top: 22px;
        margin-bottom: 0px;
    }
}

//Validacion formularios
.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label{
    color:#e41819;
}
.help-block{
    margin-top: 0;
}

.datos-trabaja {
    margin-top: 50px;
    margin-left: -16px;
} 
.datos-trabaja p{
    margin:0;
} 
.datos-trabaja .mt10{margin-top: 10px;}
.datos-trabaja .mt20{margin-top: 20px;}
.datos-trabaja p a{
    text-decoration: none;
    color: #000;
}
// input[type=checkbox] {
//     display: none;
// }
// input[type=checkbox]:checked + .checkbox:before {
//     content: "\2713";
//     text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
//     font-size: 15px;
// }
.gruesa{
    height: 2px;
    background: #000000;
}




//Video empresa
.video{
    margin: 10px 0 20px;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    iframe{
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}






